import type { FormInstance } from "element-plus"
import {ref} from 'vue';
export interface LofinFormInt {
    id:number,
    userName:string,
    passWord:string,
    permission:string,
    phone:string
}

export class InitData {
    loginForm:LofinFormInt = {
        id:0,
        userName:'',
        passWord:'',
        permission:'',
        phone:'',
    }
    loginFormRef =ref<typeof FormInstance>()
}

