import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

import router from './router'
app.use(router)
//ElementPlus
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import 'element-plus/dist/index.css'
app.use(ElementPlus, {locale: zhCn,})

//@element-plus/icons-vue
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

//在浏览器中将JSON格式数据以excel文件的形式下载
import vue3JsonExcel from "vue3-json-excel"
app.use(vue3JsonExcel)

import moment from 'moment';
import 'moment/dist/locale/zh-cn'
moment.locale('zh-cn');

app.mount('#app')