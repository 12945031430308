
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import setting from "../../config/setting"
import { InitData } from "../../types/login";
import axios from 'axios';

export default defineComponent({
  setup() {
    const data = reactive(new InitData());
    const rules = {
      // userName: [
      //   { required: false, message: "请输入账号", trigger: "blur" },
      //   { min: 6, max: 14, message: "账号长度在6到14位之间", trigger: "blur" },
      // ],
      passWord: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 6, max: 14, message: "账号长度在6到14位之间", trigger: "blur" },
      ],
    };
    const router = useRouter();
    const submitForm = () => {
      axios({
        method: 'post',
        url: setting.loginAPi,
        params: {
          userName: data.loginForm.userName,
          userPwd: data.loginForm.passWord,
        }
      }).then((res: any) => {
        //console.log('res.data', res.data)
        if (res.data.message != "登录成功") {
          ElMessage.success(res.data.message);
        } else {
          if (res.data.user.permissions == "999") {
            router.push("/home");
            //console.log(table)
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            ElMessage.success("登录成功");
          } else if (res.data.user.permissions == "普通用户") {
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            //console.log(JSON.stringify(res.data.user))
            //localStorage.setItem("User", JSON.stringify(res.data.user));
            router.push("/user-home");
          }else if (res.data.user.permissions == "附属用户") {
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            localStorage.setItem("userEquipment", res.data.user.userEquipment);
            localStorage.setItem("uid", res.data.user.uid);
            //console.log(JSON.stringify(res.data.user))
            //localStorage.setItem("User", JSON.stringify(res.data.user));
            router.push("/affiliatedUser-home");
          }else if (res.data.user.permissions == "凯米斯用户") {
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            router.push("/cheminsUser-home");
          }else if (res.data.user.permissions == "扬尘用户") {
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            router.push("/yangchenUser-home");
          }
        }
      }, (rej: any) => {
        console.log(rej)
        ElMessage.error(rej);
      })
      //console.log(table.permission)

    }
    onMounted(() => {
      history.pushState(null, "", document.URL);
      
      window.addEventListener(
        'popstate',
        function(e) {
          history.pushState(null, "", document.URL);
        },false);
    });
    return {
      ...toRefs(data),
      rules,
      submitForm,
      
    };
  },
});
